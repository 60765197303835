exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-despre-noi-tsx": () => import("./../../../src/pages/despre-noi.tsx" /* webpackChunkName: "component---src-pages-despre-noi-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invite-friends-index-tsx": () => import("./../../../src/pages/inviteFriends/index.tsx" /* webpackChunkName: "component---src-pages-invite-friends-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-preturi-tsx": () => import("./../../../src/pages/preturi.tsx" /* webpackChunkName: "component---src-pages-preturi-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-utils-undo-email-change-tsx": () => import("./../../../src/pages/utils/undoEmailChange.tsx" /* webpackChunkName: "component---src-pages-utils-undo-email-change-tsx" */)
}

